import React from 'react';
import {
    f7,
    Page,
    Card,
    CardContent,
    Preloader,
    Icon,
} from 'framework7-react';

import {API_URL} from '../../../api';
import PageBlock from '../../shared/pageBlock/pageBlock';

//CUSTOM LAYOUT
import Ev1La1 from '../../eventContainer/eventType-1/layout-1/Ev1La1';

var stylesheet;

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            showNextBtn: true,
            nextLoading: false,
            data: null
        }
    }

    componentDidMount(){
        this.loadXhr();
        if (!stylesheet) {
            stylesheet = document.createElement('style');
            document.head.appendChild(stylesheet);
        }
        document.title = "Påmeldinger.no - Laster";
    }

    loadXhr(special_code){

        let full = window.location.host;
        let parts = full.split('.');
        let sub = parts[0];
        const eid = this.props.f7route.params.id;

        this.setState({
            loading:true,
        });

        fetch(API_URL+'/event/get', {
            method: 'POST',
            body: JSON.stringify({
              eid: eid,
              uid: sub,
              special_code:special_code
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            if(data.err_code === 0){
                this.setState({
                    loading: false,
                    error: false,
                    data: data
                });
                //set document title
                document.title = data.title;
                //set color teme
                this.setColorTheme(data.options.theme_color);
            }else{
                this.setState({
                    loading: false,
                    error: true,
                    error_mgs: data.mgs,
                });
            }

        })
        .catch(err => {
        this.setState({
            loading:false,
            error:true,
            error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
        })

    }
    
    generateStylesheet(hex) {
        var styles = '';
        if (hex) {
          const colorThemeProperties = f7.utils.colorThemeCSSProperties(hex);
          if (Object.keys(colorThemeProperties).length) {
            styles += `
            body {
            ${Object.keys(colorThemeProperties)
            .map(key => `${key}: ${colorThemeProperties[key]} !important;`)
            .join('\n  ')}
            }`;
          }
        }
    return styles.trim();
    }

    setColorTheme(hex) {
        var self = this;
        if(hex && stylesheet){
            stylesheet.innerHTML = self.generateStylesheet(hex);
        }
    }

    render() {
        return this.state.loading
        ? (
            <Page>
                <div className="text-align-center" style={{marginTop:'36px',marginBottom:'160px'}}>
                    <Preloader></Preloader>
                    <p>Vent litt</p>
                </div>
            </Page>
        ) : (
            this.state.error
                ? (
                <Page>
                    <PageBlock width="920px">
                    <Card style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                        <CardContent>
                            <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                            <span style={{fontSize:'18px'}}>{this.state.error_mgs}</span>
                        </CardContent>
                    </Card>
                    </PageBlock>
                </Page>
                ) : (
                this.state.data.options.layout === 1 &&
                <Page className={(this.state.data.options.theme === 2 && 'theme-dark')}>
                    <PageBlock width="920px">
                    <Ev1La1 data={this.state.data} eid={this.props.f7route.params.id} reload_event={this.loadXhr.bind(this)}/>
                    </PageBlock>
                </Page>
                )
        )
    }
};