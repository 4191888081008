export function formateTimestamp(timestamp){

    const date = new Date(timestamp*1000);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();

    const month_arr = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];

    return day + '. ' + month_arr[month] + '. ' + year + ', ' + hours + ':' + minutes.substr(-2);
}


export function formateTimestampDate(timestamp) {

    const date = new Date(timestamp*1000);
    const day = date.getDate();

    return day;
}

export function formateTimestampTime(timestamp) {
    const date = new Date(timestamp*1000);
    const hours = "0" + date.getHours();
    const minutes = "0" + date.getMinutes();
    return hours.substr(-2) + ':' + minutes.substr(-2);
}

export function format_number(nr){
    return nr.toFixed(2).toLocaleString('en').replace(/,/g,' ');
}

export function renderDate(t){
    let a = new Date(t * 1000);
    var months = ['Jan','Feb','Mar','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Des'];
    var y = a.getFullYear();
    var m = months[a.getMonth()];
    var d = a.getDate();

    var time = d + '. ' + m + '. ' + y;
    return time;
}

export function renderTime(t){
    let a = new Date(t * 1000);
    var m = a.getMinutes();
    var h = a.getHours();

    var time = ('00'+h).slice(-2) + ':' + ('00'+m).slice(-2);
    return time;
}

export function validateEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateTel(tel){
    const re = /^([0-9]){8}$/;
    return re.test(String(tel).toString().replace(/[^0-9]/g,''));
}

export function validateOrgNr(nr){
    const re = /^([0-9]){9}$/;
    return re.test(String(nr).toString().replace(/[^0-9]/g,''));
}