import * as React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    List,
    ListItem,
    Icon,
    BlockTitle,
    Button
} from 'framework7-react';
import './eventInfoCard.css'

export default class EventInfoCard extends React.Component {

    state = {show_desc:false}
    
    formateTimestamp(timestamp) {

        const date = new Date(timestamp*1000);
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = "0" + date.getMinutes();

        const month_arr = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];

        return day + '. ' + month_arr[month] + '. ' + year + ', kl. ' + hours + ':' + minutes.substr(-2);
    }

    show_more = () => {
        let desc = this.props.data.desc.replace(/(<([^>]+)>)/gi, "");
        if(desc.length > 300){
            return true;
        }
        return false;
    }

    render() {
        return <>
        <Card className="event-info-card">
                {this.props.data.img &&
                <CardHeader
                    className="no-border"
                    valign="bottom"
                    style={{ backgroundImage: `url(${this.props.data.img})` }}
                />
                }
                <CardContent>
                    <p className="title">{this.props.data.title}</p>
                    <p className="sub-title">{this.props.data.organizer}</p>
                </CardContent>
                {(this.props.data.periode.start > 0 || this.props.data.adr || this.props.data.options.max_participants > 0) &&
                <CardContent style={{borderTop:'1px solid #E1E1E1',marginTop:'-20px'}}>
                    <List mediaList>
                        {(this.props.data.periode.start > 0 && this.props.data.periode.end > 0) &&
                        <ListItem
                            title={<>Fra <b>{this.formateTimestamp(this.props.data.periode.start)}</b><br/>Til <b>{this.formateTimestamp(this.props.data.periode.end)}</b></>}
                        >
                            <Icon slot="media" material="today"></Icon>
                        </ListItem>
                        }
                        {(this.props.data.periode.start > 0 && this.props.data.periode.end === 0) &&
                        <ListItem
                            title={<>Start <b>{this.formateTimestamp(this.props.data.periode.start)}</b></>}
                        >
                            <Icon slot="media" material="today"></Icon>
                        </ListItem>
                        }
                        {this.props.data.adr && (
                        this.props.data.adr_full ?
                        <ListItem
                            title={this.props.data.adr} subtitle={this.props.data.adr_full.secondary} text={this.props.data.adr_full.desc}
                        >
                            <Icon slot="media" f7="placemark"></Icon>
                        </ListItem>
                        :
                            <ListItem
                                title={this.props.data.adr}
                            >
                                <Icon slot="media" f7="placemark"></Icon>
                            </ListItem>
                        )}
                        {this.props.data.options.max_participants > 0 && (
                                <ListItem
                                    title={<><b>{this.props.data.available_tickets}</b> ledige plasser</>}
                                    subtitle={<>av totalt <b>{this.props.data.options.max_participants}</b> plasser</>}
                                >
                                    <Icon slot="media" material="group"></Icon>
                                </ListItem>
                            )
                        }
                    </List>
                </CardContent>
                }
            </Card>
            {(!this.props.noDesc && this.props.data.desc && this.props.data.desc.length > 10) && (
            this.state.show_desc ?
            <>
            <BlockTitle>Om {this.props.data.title}</BlockTitle>
            <Card>
                <CardContent>
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.desc }}></div>
                </CardContent>
            </Card>
            </>
            :
            <>
            <BlockTitle>Om {this.props.data.title}</BlockTitle>
            {this.show_more() ?
            <Card>
                <CardContent className="event-desc-card">
                    <div className={'desc-div'}>{this.props.data.desc.replace(/(<([^>]+)>)/gi, " ")}</div>
                    <div className="fadeout"></div>
                </CardContent>
                <CardFooter>
                    <span></span>
                    <Button onClick={()=>this.setState({show_desc:true})}>Se mer</Button>
                </CardFooter>
            </Card>
            :
            <Card>
                <CardContent className="event-desc-card">
                    <div  style={{marginBottom:'20px'}} className={'desc-div'}>{this.props.data.desc.replace(/(<([^>]+)>)/gi, " ")}</div>
                </CardContent>
            </Card>
            }
            </>
            )}
        </>;
    }
};