import * as React from 'react';
import {
    Card,
    CardContent,
    BlockTitle
} from 'framework7-react';
import './eventInfoCard.css'

import {formateTimestamp} from '../../../../functions';

export default class EicSmall extends React.Component {

    render() {
        return <>
                <BlockTitle>Praktisk informasjon</BlockTitle><Card className="event-info-card">
                <CardContent>
                    <p>
                    Påmelding for {this.props.data.periode.end > 0 ? 'dette arrangementet':'denne aktiviteten'} er åpen mellom <b>{formateTimestamp(this.props.data.singup_periode.start)}</b>{this.props.data.singup_periode.end > 0 &&<> og <b>{formateTimestamp(this.props.data.singup_periode.end)}</b></>}.
                    </p>
                    {this.props.data.options.max_participants > 0 &&
                    <p>
                        {this.props.data.periode.end > 0 ? 'Dette arrangementet':'Denne aktiviteten'} har totalt <b>{this.props.data.options.max_participants}</b> plasser.
                    </p>
                    }

                    {this.props.data.options.allow_cancel &&
                    (
                    <p>
                        Du kan melde deg av {this.props.data.periode.end > 0 ? 'dette arrangementet':'denne aktiviteten'}.
                        {this.props.data.options.cancel_time > 0 &&
                        ` Siste frist for å melde seg av er ${formateTimestamp(this.props.data.options.cancel_time)}.`
                        }
                    </p>
                    )
                    }
                </CardContent>
            </Card>
        </>;
    }
};