import React from 'react';
import {
  App,
  View,
} from 'framework7-react';

import routes from '../routes';

export default class extends React.Component {

  render() {
    return (
        <App theme="md" id="no.pameldinger.e" routes={routes}>
          <View browserHistory browserHistorySeparator="" id="main-view" url="/" main className="safe-areas" animate={false}/>
        </App>
    )
  }
};