import EventPage from './components/pages/EventPage/main';
import OrgProfilePage from './components/pages/orgProfilePage/main';
import OrgTermsPage from './components/pages/orgTermsPage/orgTermsPage';

import NotFoundPage from './components/pages/NotFoundPage';

//import ReactGA from 'react-ga';
//ReactGA.initialize('UA-159128432-1');



export default [
  {
    path: '/e/:id',
    component: EventPage,
  },
  {
    path: '/',
    component: OrgProfilePage
  },
  {
    path: '/salgsvilkar',
    component: OrgTermsPage
  },
  {
    path: '(.*)',
    component: NotFoundPage
  },
];
