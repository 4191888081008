import React from 'react';
import {
    Page,
    BlockTitle,
    Block,
    Col,
    Row,
    Link,
    Card,
    CardContent,
    Icon,
    Preloader,
    List,
    ListItem
} from 'framework7-react';

import {API_URL} from '../../../api';

import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {

    state = {
        loading: true,
        error: false,
        contact_person: false,
        org_nr: false,
        terms:''
    }

    componentDidMount(){
        this.loadXhr();
    }

    loadXhr(){
        let full = window.location.host;
        let parts = full.split('.');
        let sub = parts[0];

        this.setState({
            loading:true,
        });

        fetch(API_URL+'/org/terms', {
            method: 'POST',
            body: JSON.stringify({
              uid: sub
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            if(data.err_code === 0){
                this.setState({
                    loading: true,
                    error: false,
                    contact: data.contact,
                    org_display_name: data.org_name,
                    terms: data.sales_terms
                });
                document.title = `Salgsvilkår - ${data.org_name}`;
                this.get_data_from_brreg(data.org_nr);
            }else{
                this.setState({
                    loading: false,
                    error: true,
                    error_mgs: data.mgs,
                });
            }
        })
        .catch(err => {
        this.setState({
            loading:true,
            error:true,
            error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
        })

    }

    get_data_from_brreg = (org_nr) => {

        fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${org_nr}`, {
            method: 'GET',
            headers : new Headers(),
            })
            .then(res => res.json())
            .then(
            (result) => {
                if(result.organisasjonsnummer){


                    this.setState({
                        loading: false,
                        brreg: true,
                        org_nr: result.organisasjonsnummer,
                        org_name: result.navn,
                        org_adr: result.forretningsadresse.adresse.join(', '),
                        org_zip_code: result.forretningsadresse.postnummer,
                        org_zip_city: result.forretningsadresse.poststed,
                        org_type: result.organisasjonsnummer.beskrivelse
                    });
                    if(result.hjemmeside){
                        this.setState({web_page:result.hjemmeside});
                    }
                }else{
                    this.setState({
                        loading: false,
                        brreg: false,
                    }); 
                }

            }).catch(err => {
                this.setState({
                    loading: false,
                    brreg: false,
                });
            })
    }

    addhttp = (s) => {
        var prefix = 'http://';
        if (s.substr(0, prefix.length) !== prefix)
        {
            s = prefix + s;
        }
        return s;
    }

    render_bottombar = () => {
        return <PageBlock width="700px" color="#dfe4ea">
        <Row noGap>
          <Col width="100" small="40">
              <BlockTitle>Vilkår og personvern</BlockTitle>
              <Block>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/cookies">
                Informasjonskapsler (Cookies)
                </Link>
                </p>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/vilkar-for-deltakere">
                Vilkår for deltakere
                </Link>
                </p>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/personvern">
                Personvernerklæring
                </Link>
                </p>
            </Block>
          </Col>
          <Col width="100" small="60">
            <BlockTitle>Bruk Påmeldinger.no på ditt arrangement!</BlockTitle>
            <Block>
                <p>
                Påmeldinger.no er et påmeldingssystem for konferanser, kurs, konserter og events. Vi håndetere betaling og administrativt arbeid i forbindelse med arrangementer.
                </p>
                <Link external target="_blank" href="/">
                  <b>Les mer om Påmeldinger.no</b>
                </Link>
              </Block> 
            </Col>
          </Row>
        </PageBlock>;
    }
    render() {
        return <Page>
            {this.state.loading ?
                <div className="text-align-center" style={{marginTop:'36px',marginBottom:'654px'}}>
                    <Preloader></Preloader>
                    <p>Vent litt</p>
                </div>
            :
            <>
            {this.state.error
            ? 
            <PageBlock width="520px">
                <Card outline style={{color:'#fff', backgroundColor:'#e74c3c',maxWidth:'440px',marginLeft:'auto',marginRight:'auto',marginTop:'36px',marginBottom:'660px'}}>
                    <CardContent>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>Denne organisasjonen eksisterer ikke.</span>
                    </CardContent>
                </Card>
            </PageBlock>
            :
            <PageBlock width="680px">
                <BlockTitle large>
                Salgs- og påmeldingsvilkår
                </BlockTitle>
                {this.state.terms ?
                <div dangerouslySetInnerHTML={{__html:this.state.terms}} style={{marginBottom:'50px', padding:'20px'}}>

                </div>
                :
                <p>
                    Ingen å vise.
                </p>
                }
            </PageBlock>
            }
            </>
            }
            {!this.state.loading &&
            <PageBlock width="700px" color="#dfe4ea">
                        {this.state.contact &&
                        <>
                        <BlockTitle>
                            Kundestøtte
                        </BlockTitle>
                        <Block>
                            <p>
                            For spørsmål knyttet til arrangementene eller salg av billetter, ta kontakt med:
                            </p>
                            <b>
                            {this.state.contact.tel &&
                            <p>
                            Telefon: {this.state.contact.tel}
                            </p>
                            }
                            <p>
                            E-post: {this.state.contact.email}
                            </p>
                            </b>
                        </Block>
                        </>
                        }
                        {this.state.org_name &&
                        <>
                                <BlockTitle>Om arrangøren</BlockTitle>
                                <Block>
                                    <p>
                                    Arrangørens juridiske navn er <b>{this.state.org_name}</b>. {this.state.org_name} bruker Påmeldinger.no til å administrere sine arrangementer. {this.state.web_page && <>Finn ut mer om {this.state.org_name} på deres hjemmeside: <Link style={{textDecoration:'underline'}} target="_blank" external href={this.addhttp(this.state.web_page)}><b>{this.state.web_page}</b></Link></>}
                                    </p>
                                </Block>
                        </>
                        }
                        {this.state.brreg &&
                        <>
                        <BlockTitle>
                            Besøksadresse
                        </BlockTitle>
                        <Block>
                            <p>
                            {this.state.org_name} holder til: <b>{this.state.org_adr}, {this.state.org_zip_code} {this.state.org_zip_city}</b>
                            </p>
                        </Block>
                        <BlockTitle>
                            Valuta
                        </BlockTitle>
                        <Block>
                            <p>
                            Alle transaksjoner er i Norske kroner (NOK).
                            </p>
                        </Block>
                        </>
                        }
                        <br/>
                    </PageBlock>
                }
            {this.render_bottombar()}
        </Page>
    }
};
