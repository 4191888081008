import React, { Suspense, lazy } from 'react';
import {
    Page,
    Col,
    Row,
    Card,
    CardContent,
    Icon,
    BlockTitle,
    Block,
    Link,
    List,
    ListItem,
    Preloader
} from 'framework7-react';
import PageBlock from '../../../shared/pageBlock/pageBlock';

import EventInfoCard from '../../shared/eventInfoCard/eventInfoCard';
import EnterDataCard from '../../shared/enterDataCard/enterDataCard';
import EicSmall from '../../shared/eventInfoCard/eic-small';
import EnterSpecialCode from '../../shared/enterSpecialCode/enterSpecialCode';

const GroupRegistration = lazy(() => import('../../shared/groupRegistration/groupRegistration'));

export default class Ev1La1 extends React.Component {

    state = {
        current_time: Math.round(Date.now()/1000),
        send_data: false,
        group_signup: false,
        view_group_option: true
    }

    formateTimestamp(timestamp) {

        const date = new Date(timestamp*1000);
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = "0" + date.getMinutes();

        const month_arr = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];

        return day + '. ' + month_arr[month] + '. ' + year + ', ' + hours + ':' + minutes.substr(-2);
    }

    view_group_option = (opt) => {
        this.setState({view_group_option:opt});
    }

    render_ticket_bottombar = () => {
        return <PageBlock width="620px" className="card-background-light signup-bottom-bar">
        <Row noGap>
          <Col width="100" small="40">
              <BlockTitle>Vilkår og personvern</BlockTitle>
              <Block>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/cookies">
                Informasjonskapsler (Cookies)
                </Link>
                </p>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/vilkar-for-deltakere">
                Vilkår for deltakere
                </Link>
                </p>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/personvern">
                Personvernerklæring
                </Link>
                </p>
            </Block>
          </Col>
          <Col width="100" small="60">
            <BlockTitle>Bruk Påmeldinger.no på ditt arrangement!</BlockTitle>
            <Block>
                <p>
                Påmeldinger.no er et påmeldingssystem for konferanser, kurs, konserter og events. Vi håndetere betaling og administrativt arbeid i forbindelse med arrangementer.
                </p>
                <Link href="https://pameldinger.no/" external target={'_blank'}>
                  <b>Les mer om Påmeldinger.no</b>
                </Link>
              </Block> 
            </Col>
          </Row>
        </PageBlock>;
    }

    save_ticket_exist = (v, id) => {
        for (var i = 0; i < v.length; ++i) {
            if (v[i].eid == id) {
                return v[i];
            }
        }
        return false;
    }

    preview_existing_ticket = () => {
        const eid = this.props.eid;
        const registrations = JSON.parse(localStorage.getItem("registrations"));
        if(registrations){
            let ticket = this.save_ticket_exist(registrations, eid);
            if(ticket){
                return <>
                    <Card>
                        <List mediaList>
                            <ListItem
                                external
                                link={`https://pameldinger.no/t/${ticket.tid}`}
                                title={'Gå til eksisterende billett.'}
                                text={<>Det ser ut som du har registrert deg på dette arrangementet tidligere. <b>Trykk her for å gå til din eksisterende billett.</b></>}
                            >
                                <Icon slot="media" f7="qrcode" size={42}></Icon>
                            </ListItem>
                        </List>
                    </Card>
                    <Block style={{marginTop:'0',opacity:'.6'}}>
                        <p><i>Dersom du ikke har meldt deg på dette arrangementet tidligere, kan du se bort ifra dette varslet.</i></p>
                    </Block>
                </>;
            }
        }

    }

    render() {
        return (
            <Page>
                <PageBlock width="920px">
                <br className="hideMobileScreen"/>
                <Row>
                    <Col width="100" medium="40" style={{maxWidth:'440px',marginLeft:'auto',marginRight:'auto'}}>
                        <EventInfoCard data={this.props.data}/>
                        <EicSmall data={this.props.data}/>
                        {this.props.data.special_col_exist === true &&
                        <EnterSpecialCode data={this.props.data} reload_event={this.props.reload_event.bind(this)}/>
                        }
                        <br className="hideFullScreen"/>
                    </Col>
                    <Col width="100" medium="60" style={{maxWidth:'540px',marginLeft:'auto',marginRight:'auto'}}>
                        {this.preview_existing_ticket()}
                        {this.props.data.full && this.props.data.available_for_registration && (
                            this.props.data.options.waiting_list === 1 ?
                            <Card outline style={{backgroundColor:'#dfe4ea'}}>
                                <CardContent>
                                    <p>Dette arrangementet er fullt, men du kan likevel stille deg på venteliste. Da får du plass dersom noen melder seg av arrangement.</p>
                                </CardContent>
                            </Card>
                            :
                            <Card style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                                <CardContent>
                                    <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                                    <span style={{fontSize:'18px'}}>
                                        Dette arrangementet er fullt.
                                    </span>
                                </CardContent>
                            </Card>
                            )
                        }
                        {this.props.data.available_for_registration ?
                        <>
                        {(this.props.data.options.allow_group && this.state.view_group_option && !this.props.data.full) ?
                            <>
                            <BlockTitle style={{marginTop:'24px'}} medium>Meld deg på <b>{this.props.data.title}</b></BlockTitle>
                            <Block style={{marginBottom:'18px'}}>
                                <p>
                                    Du må fylle ut opplysningene under for å fullføre påmeldingen. Når du har fullført påmeldingen sender vi en bekreftelse på e-post.
                                </p>
                            </Block>
                            <Suspense fallback={<div className="text-align-center" style={{marginTop:'36px',marginBottom:'160px'}}><Preloader></Preloader><p>Vent litt</p></div>}>
                                <GroupRegistration data={this.props.data}/>
                            </Suspense>
                            </>
                        :
                            <EnterDataCard data={this.props.data} view_group_option={this.view_group_option.bind()}/>
                        }
                        </>
                        :
                        <>
                        <Card style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                            <CardContent>
                                <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                                <span style={{fontSize:'18px'}}>Du kan ikke melde deg på dette arrangementet.</span>
                            </CardContent>
                        </Card>

                        {this.props.data.singup_periode.start > this.state.current_time && (
                            <Card>
                                <CardContent>
                                    <span style={{fontSize:'16px'}}>Påmeldig for dette arrangementet åpner <b>{this.formateTimestamp(this.props.data.singup_periode.start)}</b>.</span>
                                </CardContent>
                            </Card>
                        )}
                        {this.props.data.singup_periode.end < this.state.current_time && (
                            <Card>
                                <CardContent>
                                    <span style={{fontSize:'16px'}}>Fristen for å melde seg på dette arrangementet gikk ut <b>{this.formateTimestamp(this.props.data.singup_periode.end)}</b></span>
                                </CardContent>
                            </Card>
                        )}

                        </>
                        }
                    </Col>
                </Row>
                </PageBlock>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                {this.render_ticket_bottombar()}
            </Page>
        );
    }
};