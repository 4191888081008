import React from 'react';
import {
    Page,
    Block,
    Button
} from 'framework7-react';


export default class extends React.Component {
  componentDidMount(){
    document.title = 'Påmeldinger.no - Laster';
    //location.reload();
  }

  render() {
    return (
  <Page>
    <Block style={{margin:'40px auto',maxWidth:'280px'}}>
      <Button onClick={()=>window.location.reload()} outline>Last inn siden på nytt</Button>
    </Block>
  </Page>);
  }
};