import React from 'react';
import {
    Page,
    BlockTitle,
    Block,
    Col,
    Row,
    Link,
    Card,
    CardContent,
    Icon,
    Preloader,
    List,
    ListItem
} from 'framework7-react';

import {API_URL} from '../../../api';
import {formateTimestamp, formateTimestampDate} from '../../../functions';

import PageBlock from '../../shared/pageBlock/pageBlock';

import './main.css';

export default class extends React.Component {

    state = {
        loading: true,
        error: false,
        contact_person: false,
        org_nr: false
    }

    componentDidMount(){
        this.loadXhr();
        this.loadEventsXhr();
    }

    loadXhr(){
        let full = window.location.host;
        let parts = full.split('.');
        let sub = parts[0];
        
        this.setState({
            loading:true,
        });

        fetch(API_URL+'/org/get', {
            method: 'POST',
            body: JSON.stringify({
              uid: sub
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            if(data.err_code === 0){
                this.setState({
                    loading: true,
                    error: false,
                    contact: data.contact,
                    org_display_name: data.org_name,
                    sale_terms: data.sale_terms
                });
                document.title = `Arrangementer - ${data.org_name}`;
                this.get_data_from_brreg(data.org_nr);
            }else{
                this.setState({
                    loading: false,
                    error: true,
                    error_mgs: data.mgs,
                });
            }
        })
        .catch(err => {
        this.setState({
            loading:true,
            error:true,
            error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
        })

    }

    loadEventsXhr(){

        let full = window.location.host;
        let parts = full.split('.');
        let sub = parts[0];

        this.setState({
            loading_event:true,
        });

        fetch(API_URL+'/org/events', {
            method: 'POST',
            body: JSON.stringify({
              uid: sub
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            if(data.err_code === 0){
                this.setState({
                    loading_event: false,
                    error_event: false,
                    events: data.events,
                    activities: data.activities
                });
            }else{
                this.setState({
                    loading_event: false,
                    error_event: true,
                    error_event_mgs: data.mgs,
                });
            }
        })
        .catch(err => {
        this.setState({
            loading_event:true,
            error_event:true,
            error_event_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
        })

    }

    get_data_from_brreg = (org_nr) => {

        fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${org_nr}`, {
            method: 'GET',
            headers : new Headers(),
            })
            .then(res => res.json())
            .then(
            (result) => {
                if(result.organisasjonsnummer){


                    this.setState({
                        loading: false,
                        brreg: true,
                        org_nr: result.organisasjonsnummer,
                        org_name: result.navn,
                        org_adr: result.forretningsadresse.adresse.join(', '),
                        org_zip_code: result.forretningsadresse.postnummer,
                        org_zip_city: result.forretningsadresse.poststed,
                        org_type: result.organisasjonsnummer.beskrivelse
                    });
                    if(result.hjemmeside){
                        this.setState({web_page:result.hjemmeside});
                    }
                }else{
                    this.setState({
                        loading: false,
                        brreg: false,
                    }); 
                }

            }).catch(err => {
                this.setState({
                    loading: false,
                    brreg: false,
                });
            })
    }

    addhttp = (s) => {
        var prefix = 'http://';
        if (s.substr(0, prefix.length) !== prefix)
        {
            s = prefix + s;
        }
        return s;
    }

    render_available = (r,m) => {

        let sum = r - m;
        if(sum <= 0){
            return <>0 ledige</>;
        }else{
            return <>{sum} ledige</>;
        }
    }

    render_bottombar = () => {
        return <PageBlock width="700px" color="#dfe4ea">
        <Row noGap>
          <Col width="100" small="40">
              <BlockTitle>Vilkår og personvern</BlockTitle>
              <Block>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/cookies">
                Informasjonskapsler (Cookies)
                </Link>
                </p>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/vilkar-for-deltakere">
                Vilkår for deltakere
                </Link>
                </p>
                <p style={{marginTop:'-8px'}}>
                <Link external target={'_blank'} href="https://pameldinger.no/personvern">
                Personvernerklæring
                </Link>
                </p>
            </Block>
          </Col>
          <Col width="100" small="60">
            <BlockTitle>Bruk Påmeldinger.no på ditt arrangement!</BlockTitle>
            <Block>
                <p>
                Påmeldinger.no er et påmeldingssystem for konferanser, kurs, konserter og events. Vi håndetere betaling og administrativt arbeid i forbindelse med arrangementer.
                </p>
                <Link external target="_blank" href="/">
                  <b>Les mer om Påmeldinger.no</b>
                </Link>
              </Block> 
            </Col>
          </Row>
        </PageBlock>;
    }
    render() {
        return <Page>
            {this.state.loading_event ?
                <div className="text-align-center" style={{marginTop:'36px',marginBottom:'654px'}}>
                    <Preloader></Preloader>
                    <p>Vent litt</p>
                </div>
            :
            <>
            {this.state.error
            ? 
            <PageBlock width="520px">
                <Card outline style={{color:'#fff', backgroundColor:'#e74c3c',maxWidth:'440px',marginLeft:'auto',marginRight:'auto',marginTop:'36px',marginBottom:'660px'}}>
                    <CardContent>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>Denne organisasjonen eksisterer ikke.</span>
                    </CardContent>
                </Card>
            </PageBlock>
            :
            <PageBlock width="880px">
            {this.state.events ? (
                <>
                <div className={'hideMobileScreen'}>
                {this.state.events.map((n,i) => (
                <Card className={'event-display elevation-2'} key={n.event_id}>
                    <List mediaList>
                        <ListItem
                            link={`https://pameldinger.no/e/${n.event_id}`}
                            external
                            title={n.title}
                        >
                            <div slot="media" className={'event-media-img'} style={{backgroundImage:`url(${n.img})`,borderRight:"1px solid #E1E1E1"}}>
                                <Card className={'cal-display'}>
                                    <div className={'cal-top-bar'}></div>
                                    <div className={'cal-date'}>{formateTimestampDate(n.date.start)}.</div>
                                </Card>
                            </div>

                            <div className="event-info-card" style={{borderTop:"1px solid #E1E1E1",marginTop:'8px'}}>

                                {(n.date.start > 0 || this.props.data.adr || this.props.data.options.max_participants > 0) &&
                                    <List>
                                        {(n.date.start > 0 && n.date.end > 0) &&
                                        <ListItem
                                            title={<>Fra: <b>{formateTimestamp(n.date.start)}</b><br/>Til <b>{formateTimestamp(n.date.end)}</b></>}
                                        />
                                        }
                                        {(n.date.start > 0 && (n.date.end === 0 || !n.date.end)) &&
                                        <ListItem
                                            title={<>Start: <b>{formateTimestamp(n.date.start)}</b></>}
                                        />
                                        }
                                        {n.location && (
                                        n.location.main ?
                                        <ListItem
                                            title={<>Hvor: <b>{n.location.main}</b></>}
                                        />
                                        :
                                            <ListItem
                                                title={<>Hvor: <b>{n.location}</b></>}
                                            />
                                        )}
                                        {n.options.max_participants > 0 && (
                                                <ListItem
                                                    title={<><b>{n.singup.available}</b> ledige plasser av totalt {n.options.max_participants} plasser</>}
                                                />
                                            )
                                        }
                                    </List>
                                }   
                            </div>

                        </ListItem>
                    </List>
                </Card>
                ))}
                </div>
                <div className={'hideFullScreen'}>
                {this.state.events.map((n,i) => (
                <a className={'external'} key={n.event_id} href={`https://pameldinger.no/e/${n.event_id}`}>
                    <Card className={'event-display-card elevation-2'}>
                        <CardContent padding={false}>
                            <div className={'event-card-img'} style={{backgroundImage:`url(${n.img})`}}>
                                <Card className={'cal-display'}>
                                    <div className={'cal-top-bar'}></div>
                                    <div className={'cal-date'}>{formateTimestampDate(n.date.start)}.</div>
                                </Card>
                            </div>
                        </CardContent>
                        <List mediaList>
                        <ListItem
                            link={`https://pameldinger.no/e/${n.event_id}`}
                            external
                            title={n.title}
                        >
                            <div className="event-info-card" style={{borderTop:"1px solid #E1E1E1",marginTop:'8px'}}>

                                {(n.date.start > 0 || this.props.data.adr || this.props.data.options.max_participants > 0) &&
                                    <List>
                                        {(n.date.start > 0 && n.date.end > 0) &&
                                        <ListItem
                                            title={<>Fra: <b>{formateTimestamp(n.date.start)}</b><br/>Til <b>{formateTimestamp(n.date.end)}</b></>}
                                        />
                                        }
                                        {(n.date.start > 0 && (n.date.end === 0 || !n.date.end)) &&
                                        <ListItem
                                            title={<>Start: <b>{formateTimestamp(n.date.start)}</b></>}
                                        />
                                        }
                                        {n.location && (
                                        n.location.main ?
                                        <ListItem
                                            title={<>Hvor: <b>{n.location.main}</b></>}
                                        />
                                        :
                                            <ListItem
                                                title={<>Hvor: <b>{n.location}</b></>}
                                            />
                                        )}
                                        {n.options.max_participants > 0 && (
                                                <ListItem
                                                    title={<><b>{n.singup.available}</b> ledige plasser av totalt {n.options.max_participants} plasser</>}
                                                />
                                            )
                                        }
                                    </List>
                                }   
                            </div>

                        </ListItem>
                    </List>
                    </Card>
                </a>
                ))}
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                </>
            ):(
            <Block>
                <p>
                {this.state.org_display_name} har ingen kommende arrangementer du kan melde deg på.
                </p>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </Block>
            )
            }
            </PageBlock>
            }
            </>
            }
            {!this.state.loading &&
            <PageBlock width="700px" color="#dfe4ea">
                        {this.state.contact &&
                        <>
                        <BlockTitle>
                            Kundestøtte
                        </BlockTitle>
                        <Block>
                            <p>
                            For spørsmål knyttet til arrangementene eller salg av billetter, ta kontakt med:
                            </p>
                            <b>
                            <p>
                            Telefon: {this.state.contact.tel}
                            </p>
                            <p>
                            E-post: {this.state.contact.email}
                            </p>
                            </b>
                        </Block>
                        </>
                        }
                        {this.state.org_name &&
                        <>
                                <BlockTitle>Om arrangøren</BlockTitle>
                                <Block>
                                    <p>
                                    Arrangørens juridiske navn er <b>{this.state.org_name}</b>. {this.state.org_name} bruker Påmeldinger.no til å administrere sine arrangementer. {this.state.web_page && <>Finn ut mer om {this.state.org_name} på deres hjemmeside: <Link style={{textDecoration:'underline'}} target="_blank" external href={this.addhttp(this.state.web_page)}><b>{this.state.web_page}</b></Link></>}
                                    </p>
                                </Block>
                        </>
                        }
                        {this.state.sale_terms &&
                        <>
                            <BlockTitle>
                                {this.state.org_name?this.state.org_name:'Arrangøren'} sine salgs- og påmeldingsvilkår
                            </BlockTitle>
                            <Block>
                                <p>
                                <a href={'/salgsvilkar'}>Her (<span style={{textDecoration:'underline'}}>https://{window.location.host.split('.')[0]}.pameldinger.no/salgsvilkar</span>) finner du {this.state.org_name?this.state.org_name:'arrangøren'} sine salgs og påmeldingsvilkår som gjelder når du melder deg på {this.state.org_name?this.state.org_name:'arrangøren'} sine arrangmenter.</a>
                                </p>
                            </Block>
                        </>
                        }
                        {this.state.brreg &&
                        <>
                        <BlockTitle>
                            Besøksadresse
                        </BlockTitle>
                        <Block>
                            <p>
                            {this.state.org_name} holder til: <b>{this.state.org_adr}, {this.state.org_zip_code} {this.state.org_zip_city}</b>
                            </p>
                        </Block>
                        <BlockTitle>
                            Valuta
                        </BlockTitle>
                        <Block>
                            <p>
                            Alle transaksjoner er i Norske kroner (NOK).
                            </p>
                        </Block>
                        </>
                        }
                        <br/>
                    </PageBlock>
                }
            {this.render_bottombar()}
        </Page>
    }
};
