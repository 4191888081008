import * as React from 'react';
import {
    Card,
    CardContent,
    BlockTitle,
    List,
    ListInput,
    Button,
    Block
} from 'framework7-react';

export default class EnterSpecialCode extends React.Component {

    state = {
        code: ''
    }

    check_code = () => {
        if(this.state.code){
            this.props.reload_event(this.state.code);
        }
    }

    render() {
        return <>
                <BlockTitle>Kampanjekode</BlockTitle><Card className="event-info-card">
                <CardContent>
                    <List>
                        <ListInput
                            type="text"
                            value={this.state.code}
                            placeholder={'Skriv inn kampanjekode'}
                            errorMessage={'Skriv inn kampanjekode'}
                            clearButton
                            onChange={(e)=>this.setState({code:e.target.value})}
                            onInputClear={()=>this.setState({code:''})}
                        />
                    </List>
                </CardContent>
            </Card>
            <Block style={{marginTop:'18px'}}>
                <Button disabled={!this.state.code} fill onClick={()=>this.check_code()}>
                    Sjekk kampanjekode
                </Button>
            </Block>
        </>;
    }
};